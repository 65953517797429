$paragraph-margin-bottom: 0;
$secondary: rgb(235, 235, 235);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1600px,
    xxl: 2500px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 800px,
    xl: 960px,
    xxl: 1440px,
);

@import "~bootstrap/scss/bootstrap";

.myBox a{
color: #fcbf00 !important;
&:hover{
color: #073F71 !important;
    
}
}

a{
    
    &:hover{
    text-decoration: none !important;
        
    }
    }

body {
    min-height: 100vh;
    background: linear-gradient(
        203deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 50%,
        rgba(255, 255, 255, 0.9) 100%
    );
    scrollbar-width: 0 !important;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-visibility: hidden;
    -ms-overflow-style: none;

}

#root {
    height: 100vh;
    font-family: "Open Sans","Roboto", sans-serif;
}


main {
    background: white;
    background-size: cover;
    background-attachment: fixed;
}

hr{
    color: white !important;
    background-color: white !important;

}


#main-welcome {
    background: transparent linear-gradient(115deg, #ffffff 0%, #ffffff 51%, #ffffff 100%) 0% 0% no-repeat padding-box;


    background-size: cover;
    background-attachment: fixed;
}
.danger input {
    &::-webkit-input-placeholder {
        color: red;
    }
    &::-moz-placeholder {
        color: red;
    }
    &:-ms-input-placeholder {
        color: red;
    }
    &:-moz-placeholder {
        color: red;
    }
}

.danger {
    border: red solid 2px !important;
}

.coolie-consent{
    text-align: center;
}

.app-bar {
    background: #fff !important;
}

.home-app-bar {
}

.app-bar-titel {
    max-height: 50px;
}

.widget--poweredby {
    display: none !important;
}

.style__ChatRoomPoweredBy-gIBgEp {
    display: none !important;
}

.goBackIcon:hover {
    cursor: pointer;
}

#main-container {
}
.user-selector {
}
.user-selector:hover {
}

.messages-box {
    padding-top: 10px;
    max-height: 100% !important;
    height: 100% !important;
    overflow-y: scroll;
    display: flex;
    border-radius: 8px;
    flex-direction: column-reverse;
    &::-webkit-scrollbar {
        display: none;
    }
}

.message-box:last-child {
    position: sticky;
    bottom: 0;
}
.messages-outer {
    bottom: 90px;
    height: calc((100vh - 225px));
    position: absolute;
    overflow-y: scroll;
    width: 100%;
    scrollbar-width: none;
}

.support-wrapper {
    top: 123px;
    position: absolute;
    text-align: center;
    height: calc((100% - 123px));
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}

.message-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.message-form {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 15px;
    width: 270px;
}
.message-send {
    margin-right: 10px;
    margin-left: 5px;
}

.message-input {
    flex-grow: 8;
    background-color: white;
    border-radius: 5px;
}

.message-username {
    color: white;
    text-transform: capitalize;
    font-weight: 800;
    margin-bottom: 5px;
}
.message-last {
    color: white;
}

.users-image {
    width: 50px;
    height: 50px;
    objectfit: cover;
    border-radius: 50%;
    filter: invert(1);
}
.sponsor-image {
    width: 90%;
    border-radius: 10px;
}
.sponsor-image:hover {
    cursor: pointer;
}

.info-text {
    color: white;
    text-align: center;
}
.login-stripe {
    background-size: cover;
    background-image: url("https://resources.wirkung.stream/projects/cbc/img/main-stripe.png");
  
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
}

.login-stripe2 {
    background-size: cover;
    background-image: linear-gradient(rgb(0, 0, 0) 45%, rgb(225, 0, 0) 100%) !important;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-big {
    min-width: 275px;
    height: 50px;
}
.countdown-container {
    display: flex !important;
    align-items: flex-start;
    margin-bottom: 50px;
}
.landing-scroll{
    position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

    
}

.xy-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.y-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .login-stripe {
        height: 80vh;
    }
    .home-container {
        max-width: 100%;
        margin: 0;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .login-stripe {
        height: 75vh;
    }
    .home-container {
        max-width: 100%;
        margin: 0;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .login-stripe {
        height: 55vh;
    }
    .home-container {
        max-width: 100%;
        margin: 0;
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .login-stripe {
        height: 50vh;
    }
    .home-container {
        max-width: 80%;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .login-stripe {
        height: 40vh;
    }
    .home-container {
        max-width: 60%;
    }
}

/*-----------------Header Component Start--------------------*/

.headerComponent .logo {
    content: url(https://resources.wirkung.stream/projects/123/theme/logo.png);
    max-height: 50px;
}
.headerComponent .flex {
    padding: 20px 0;
}
.headerComponent .flex-item {
    padding: 20px 0;
}

/*-----------------Header Component End--------------------*/

/*-----------------Footer Component Start--------------------*/

.footerComponent .flex {
    padding: 40px 0;
}
#home-footer .footer, .page-wrap:after {
    margin-top: -1px;
    padding-top: 60px;
}
.footerComponent .footer {
}
.footerComponent .flex-item {
    margin-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        text-align: center !important;
    }
}

#bgimageNativPlayerError{
    display: none !important;
}
#bgimageNativPlayerError img{
    display: none !important;
}


/*-----------------Footer Component End--------------------*/

/*-----------------Landing Page Start--------------------*/









.landing-outer{
    position: absolute;
    background: transparent linear-gradient(115deg, #ffffff 0%, #ffffff 51%, #ffffff 100%) 0% 0% no-repeat padding-box;

    left: 0;
    top: 64px;
    width: 100%;

    @media (max-width: 960px) {
    top:50px;
        
    }
}


.landing-bg img{   
    position: relative;

    width: 100%;
    height: auto;
}

.landing-gradient {    
    position: relative;
    left: 0;
    width: 100%;    

    min-height: 200px;
    height:fit-content;
    max-height: 4000px;
    display: flex;
    justify-content: center;   
    background-clip:border-box;

-moz-background-clip:border-box;

@media (min-width: 996px) {

}
@media (min-width: 1700px) {
    min-height: 200px;


}
@media (min-width: 2500px) {
    min-height: 200px;

}

@media (min-width: 3000px) {
    min-height: 200px;

}


}

.landing-content{
    position: relative;   
    padding-top: 50px;


}













.landingPage .stripe2 {
    background-image: linear-gradient(rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%) !important;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    height: 60vh;
    background-position: center;

    @media (min-width: 576px) {
        height: 80vh;
    }
    @media (min-width: 768px) {
        height: 75vh;
    }
    @media (min-width: 992px) {
        height: 70vh;
    }
    @media (min-width: 1200px) {
        height: 65vh;
    }
    @media (min-width: 1400px) {
        height: 65vh;
    }
}

.landingPage .stripe {
    background-image: linear-gradient(rgb(0, 0, 0) 45%, rgb(143, 0, 0) 100%) !important;
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 120px;
    padding-bottom: 80px;
    @media (min-width: 1400px) {
        min-height: 50vh;
        padding-top: 160px;
        padding-bottom: 110px;
    }
    @media (min-width: 2000px) {
        padding-top: 220px;
        padding-bottom: 180px;
        min-height: 50vh;

    }

    @media (min-width: 3000px) {
        padding-top: 290px;
        padding-bottom: 220px;
        min-height: 50vh;

    }
    @media (min-width: 3500px) {
        padding-top: 510px;
        padding-bottom: 450px;
        min-height: 50vh;

    }
}
.app-bar-image {
    max-height: 30px;
    align-self: center;
    background-size: contain;
}
.landingPage .app-bar-inner {
    justify-content: space-between;
}

.app-bar-inner {
    justify-content: space-between;
}

.app-bar-inner-welcome {
    justify-content: space-between;
}

.boxShadow{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.landingPage .btn {
    color: #fff !important;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    &:hover {
        -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
        box-shadow: 5px 20px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s;
        background-color: #F2B700 !important;
    }
    &:after {
        content: "Zum Login";
    }
}

.countdown-text {
    color: white;
    line-height: 4rem;
}
.countdown-number {
    color: white;
}

.loginForm h3 {
    margin: 0;
    padding: 5px 5px 10px 10px;
}

.loginForm {
    background: transparent !important;
}
.loginForm .login-btn {
    color: #172154 !important;
    font-style: italic;
    text-decoration: none;
    padding: 15px;
    display: inline-block;
background-color: #fcbf00;
font-weight: 400;
font-size: larger;
    border: none;
    transition: all 0.4s ease 0s;
    &:hover {
        -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
        -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.57);
        box-shadow: 5px 20px -10px rgba(0, 0, 0, 0.57);
        transition: all 0.4s ease 0s;
        background-color: #F2B700 !important;

    }
}

.login-card-header {
    background-color: black;
    color: white;
}

.login-card-header input:first-of-type{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.wildcard {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 40px 30px !important;
}
.stripe h2 {
    color: white;
    line-height: 40px;
}
.stripe h3 {
    color: white;
}
.stripe p {
    color: white;
    font-size: large;
    line-height: 40px;
}
.myCol {
    padding: 0 30px;
}

/*-----------------Landing Page  End--------------------*/

/*-----------------Home Page  Start--------------------*/

.videodiv {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    border-radius: 10px;
    margin-bottom: -1px;
}


.videoiframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 0px;
    border-radius: 5px 5px 0px 0px;
    z-index: 1000 !important;
}

.video-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 88px;

    border-radius: 0px 0px 5px 5px;
    margin-bottom: 30px;
}




.card-footer {
    background-color: white;
font-size: 16px;
    font-weight: 800;
    border-top: 0px solid rgba(0, 0, 0, 0.125) !important;
}

.home-container {
    max-width: 60%;
    min-height: 90vh;

    @media (max-width: 1200px) {
        max-width: 100%;
        margin-left: 3%;

        margin: 0;
    }
}

.home-bg {
    position: relative;
    
    width: 100%;
    height: auto;

}
.home-outer{
    position: absolute;
    background: transparent linear-gradient(115deg, #ffffff 0%, #ffffff 51%, #ffffff 100%) 0% 0% no-repeat padding-box;


    left: 0;
    top: 64px;
    width: 100%;
    @media (max-width: 960px) {
        top:50px;
            
        }
}
.home-bg img{   
    position: relative;

    width: 100%;
    height: auto;
}

.home-gradient {    
    position: relative;
    left: 0;

    width: 100%;      

    min-height: 500px;
    height:fit-content;
    max-height: 4000px;
    display: flex;
    justify-content: center;   
    background-clip:border-box;

-moz-background-clip:border-box;
@media (min-width: 1700px) {
    min-height: 800px;

}

@media (min-width: 2500px) {
    min-height: 1100px;

}


}

.home-content{
    position: relative;   
    padding-top: 50px;



}

.style__LiveChatRoomContainer-hdNjZN {
    z-index: 1201 !important;
}
.style__LiveChatRoomContainer-hxBfUD {
    z-index: 1201 !important;
}


.arena--conversion-card{
    border-radius: 6px !important;
padding: -5px;
}

/*-----------------Home Page  End--------------------*/

/*-----------------Home Page  Start--------------------*/

.welcome-container {
    min-height: 90vh;
    color: white;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
}
.welcome-container .myBox {
    margin-top: 2%;
    margin-bottom: 2%;
    max-width: 750px;
    color:#fcbf00;
    margin: 50px 120px;
    margin: auto;
    
    
}

.welcome-container h1 {
    color: white;
}
.welcome-container h4 {
    color: white;
}
.card-content{
    background-color: white;
    color: black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 18px ;
    text-align: left;

}




/*-----------------Welcome Page  End--------------------*/

.sidebar-menue-text {
    color: white;
}
/*
.gioiWr{
    justify-content: center !important;
}
*/
.my-danger {
    background-color: #ff000055 !important;
}



.welcome-bg {
    position: relative;
    
    width: 100%;
    height: auto;

}
.welcome-outer{
    position: absolute;
    background: transparent linear-gradient(115deg, #ffffff 0%, #ffffff 51%, #ffffff 100%) 0% 0% no-repeat padding-box;


    left: 0;
    top: 64px;
    width: 100%;
    @media (max-width: 960px) {
        top:50px;
            
        }
}
.welcome-bg img{   
    position: relative;

    width: 100%;
    height: auto;
}

.welcome-gradient {    
    position: relative;
    left: 0;
    width: 100%;    

    min-height: 400px;
    height:fit-content;
    max-height: 4000px;
    display: flex;
    justify-content: center;   
    background-clip:border-box;

-moz-background-clip:border-box;

@media (min-width: 996px) {

}
@media (min-width: 1700px) {
    min-height: 300px;


}
@media (min-width: 2500px) {
    min-height: 300px;

}

@media (min-width: 3000px) {
    min-height: 700px;

}


}

.welcome-content{
    position: relative;   
    padding-top: 50px;


}
.MuiButton-label{
}

.arena-widget--social-wall--cards{
    min-height: 500px;
}
.arena--conversion-card{
    min-height: 490px;

}

#rcc-confirm-button{
    position: absolute;
    bottom: 0px;
    right: 0px;
}
